import { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player/vimeo';
import CountdownTimer from '../CountdownTimer';
import useWebinarStore from '../../utils/store';

import './index.css';

const WebinarVideo = ({
	minimized,
	status, // live, finished, early
	vimeo_id,
	replay,
	videoTimer,
	start_time,
	welcomeMessage
}) => {
	const [hasStarted, setHasStarted] = useState(false);
	const playerRef = useRef(null);
	const playedWelcome = useWebinarStore((state) => state.playedWelcome);

	const dimPlayer = (op = 0.5) => {
		//make the video and play button semi transparent
		const video = document.querySelector('.video-player');
		if (video) {
			video.style.opacity = op;
		}
		const button = document.querySelector('.webinar-start-button');
		if (button) {
			button.style.opacity = op;
		}
	};

	const playWelcome = async () => {
		if (!welcomeMessage) return;
		if (!welcomeMessage.voice) return;
		if (!welcomeMessage.messages) return;

		//make the video and play button semi transparent
		dimPlayer();

		try {
			const msgArray = JSON.parse(welcomeMessage.messages);
			const welcomeMessageText = msgArray.messages[
				Math.floor(Math.random() * msgArray.messages.length)
			].replace('[first_name]', welcomeMessage.first_name);

			const playAudio = (url) => {
				return new Promise((resolve, reject) => {
					const audio = new Audio(url);
					audio.addEventListener('ended', {
						handleEvent: () => {
							//sleep for 1 second
							// setTimeout(() => {
							// 	resolve();
							// }, 1000);
							resolve();
						}
					});
					audio.addEventListener('error', (e) => {
						console.error('Audio playback error:', e);
						reject(e);
					});

					try {
						const playPromise = audio.play();
						if (playPromise !== undefined) {
							playPromise
								.then(() => {
									// Audio playback started successfully
								})
								.catch((error) => {
									console.error(
										'Audio playback failed:',
										error
									);
									reject(error);
								});
						}
					} catch (error) {
						console.error('Audio playback error:', error);
						reject(error);
					}
				});
			};

			const playWelcomeAudio = async (text) => {
				const url = `https://knowledgesource.com.au/mp3.php?text=${encodeURIComponent(
					text
				)}&voice=${encodeURIComponent(welcomeMessage.voice)}`;
				const timeout = new Promise(
					(_, reject) =>
						setTimeout(
							() => reject(new Error('Audio playback timeout')),
							10000
						) // 10 seconds timeout
				);

				try {
					await Promise.race([playAudio(url), timeout]);
				} catch (error) {
					console.error('Audio playback failed or timed out:', error);
				}
			};

			await playWelcomeAudio(welcomeMessageText);
		} catch (e) {
			console.error('Error playing welcome message:', e);
		}
		dimPlayer(1);
	};

	const startWebinar = async () => {
		// when the button is pushed, start the webinar
		if (!playerRef || !playerRef.current) return;

		if (
			!playedWelcome &&
			!replay &&
			welcomeMessage &&
			welcomeMessage.playWelcome
		) {
			useWebinarStore.setState((state) => ({
				playedWelcome: true
			}));
			await playWelcome();
		}

		setHasStarted(true);
		const secondsPast = getLivePosition();
		try {
			if (
				secondsPast > 0 &&
				secondsPast < playerRef.current.getDuration()
			) {
				playerRef.current.seekTo(secondsPast);
			}
		} catch (e) {
			console.error('Error seeking:', e);
		}
	};

	const handleSeek = () => {
		// if they somehow work out how to seek
		if (replay) return;
		const secondsPast = getLivePosition();

		if (
			playerRef.current.getCurrentTime() > secondsPast + 5 ||
			playerRef.current.getCurrentTime() < secondsPast - 5
		) {
			if (
				secondsPast > 0 &&
				secondsPast < playerRef.current.getDuration()
			) {
				playerRef.current.seekTo(secondsPast);
			}
		}
	};

	const handlePause = () => {
		// Unpause if somehow managed to be paused
		if (replay) return;
		playerRef.current.getInternalPlayer().play();
	};

	const getLivePosition = () => {
		// calculate how many seconds have past since the start_time
		const now = new Date();
		const startTime = new Date(start_time);
		const secondsPast = (now - startTime) / 1000;
		return secondsPast;
	};

	return (
		<>
			{(status === 'live' || replay) && (
				<div
					className={`video-player-wrapper ${
						minimized ? 'minimized' : ''
					}`}
				>
					<ReactPlayer
						url={`https://vimeo.com/${vimeo_id}`}
						ref={playerRef}
						className="video-player"
						playing={hasStarted}
						controls={replay ? true : false}
						onSeek={handleSeek}
						width="100%"
						playsinline={true}
						height="100%"
						onProgress={videoTimer}
						onPause={handlePause}
						onPlay={startWebinar}
					/>
					{!hasStarted && !minimized && (
						<button
							className="webinar-start-button"
							onClick={startWebinar}
						>
							<i className="fas fa-play"></i>{' '}
							{replay ? 'Watch the Replay' : 'Join the Webinar'}
						</button>
					)}
					{!minimized && replay && (
						<div className="replay-indicator">REPLAY</div>
					)}
					{!minimized && !replay && (
						<div className="live-indicator">LIVE</div>
					)}
				</div>
			)}
			{status === 'finished' && !replay && (
				<div className="webinar-finished">
					<h1>The webinar has finished</h1>
				</div>
			)}

			{status === 'early' && (
				<div className="webinar-finished">
					<h1>The webinar will begin in:</h1>
					<CountdownTimer
						startTime={new Date(start_time)}
						duration={0}
						prefix=""
					/>
				</div>
			)}
		</>
	);
};

export default WebinarVideo;
